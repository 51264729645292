import NiceInput from '@/components/Input'
import Loader from '@/components/Loader'

import input from '@/mixins/input'
import {validationMixin} from 'vuelidate'

export default {
  mixins: [
    input,
    validationMixin
  ],

  components: {
    Loader,
    NiceInput
  },

  props: {
    loading: Boolean
  },

  methods: {
    submit() {
      this.$v.$touch()

      if(!this.$v.$invalid) {
        this.$emit('submit', this.resource)
        this.resource = {}
        this.$v.$reset()
      }
    }
  }
}
