<template>
  <div class="page-astc">
    <banner :img="require('../assets/img/banners/net.jpg')">
      Tennis coaching for all ages &amp; abilities
      <br>
      <router-link class="btn" :to="{name: 'bookings'}">Book now!</router-link>
    </banner>

    <container :title="title">
      <div v-html="content"></div>
    </container>
  </div>
</template>

<script>
  import Banner from '@/components/Banner'
  import Container from '@/components/Container'

  import page from '@/mixins/page'

  export default {
    name: "ASTC",

    mixins: [
      page
    ],

    components: {
      Banner,
      Container
    },

    data() {
      return {
        pageSlug: 'astc'
      }
    }
  }
</script>

<style lang="sass">
  .page-astc
</style>
