<template>
  <div class="banner">
    <lazy-img class="background" :url="img"></lazy-img>

    <div class="words">
      <slot></slot>
    </div>
  </div>
</template>

<script>
  import LazyImg from '@/components/LazyImg'

  export default {
    name: "Banner",

    components: {
      LazyImg
    },

    props: {
      img: {
        type: String,
        required: true
      }
    }
  }
</script>

<style lang="sass">
  @import "../stylesheets/base/variables"
  @import "../stylesheets/helpers/mixins"

  .banner
    width: 100%
    height: 300px
    position: relative
    display: flex
    justify-content: center
    align-items: center
    padding: $pad
    overflow: hidden

    @include media($media-gt-big)
      height: 500px

    .background
      position: absolute
      top: 0
      right: 0
      bottom: 0
      left: 0
      pointer-events: none
      background: #738c76
      display: flex
      align-items: center
      justify-content: center
      color: #ffffff

      > img
        width: 100%
        height: 100%
        object-fit: cover

    .words
      font-size: 28px
      background: rgba(255,255,255,0.4)
      padding: $pad
      font-weight: bold
      text-align: center
      z-index: 1

      @include media($media-gt-big)
        position: absolute
        top: 50%
        right: 10%
        left: auto
        transform: translate(0, -50%)
        max-width: 350px
</style>
