import Axios from 'axios'

const host = process.env.VUE_APP_API_HOST
const path = process.env.VUE_APP_API_URL_BASE

export function getPostsData() {
  const url = `${host}/${path}/posts`

  return Axios.get(url, {
    params: {
      _embed: true
    }
  })
}

export function getPostData(slug) {
  const url = `${host}/${path}/posts/`

  return Axios.get(url, {
    params: {
      slug,
      _embed: true
    }
  })
}
