<template>
  <div class="page-post">
    <lazy-img
      class="post-image"
      v-if="hasFeaturedImage"
      :url="featuredImageUrl"
      :description="featuredImageDescription"
    />

    <container :title="title">
      <div>Posted on: {{ post.date | date }}</div>
      <div>Last updated on: {{ post.modified | date }}</div>

      <div v-html="content"></div>
    </container>
  </div>
</template>

<script>
import Container from '@/components/Container'
import LazyImg from '@/components/LazyImg'

import page from '@/mixins/page'

import {mapState, mapActions} from 'vuex'

const LOADING_TEXT = 'Loading...'

export default {
  name: 'Post',

  props: {
    slug: {
      type: String,
      required: true
    }
  },

  mixins: [page],

  components: {
    Container,
    LazyImg
  },

  filters: {
    date(value) {
      if (!value) {
        return ''
      }

      const date = new Date(value)

      const day = date.getDate()
      const month = date.getMonth() + 1
      const year = date.getFullYear()

      return `${day}/${month}/${year}`
    }
  },

  data() {
    return {
      pageSlug: 'post'
    }
  },

  computed: {
    ...mapState(['postsBySlug']),

    post() {
      return this.postsBySlug[this.slug] || {}
    },

    title() {
      return this.post.title ? this.post.title.rendered : LOADING_TEXT
    },

    content() {
      return this.post.content ? this.post.content.rendered : LOADING_TEXT
    },

    hasFeaturedImage() {
      return !!this.post._embedded && !!this.post._embedded['wp:featuredmedia']
    },

    featuredMedia() {
      if (this.hasFeaturedImage) {
        return this.post._embedded['wp:featuredmedia'][0]
      }

      return false
    },

    featuredImageUrl() {
      if (this.hasFeaturedImage) {
        return this.featuredMedia.source_url
      }

      return false
    },

    featuredImageDescription() {
      if (this.hasFeaturedImage) {
        return this.featuredMedia.alt_text
      }

      return false
    }
  },

  methods: {
    ...mapActions(['getPost']),

    getData() {
      const slug = this.slug
      this.getPost({slug})
    }
  },

  serverPrefetch() {
    this.getData()
  },

  mounted() {
    if (!this.postsBySlug[this.slug]) {
      this.getData()
    }
  }
}
</script>

<style lang="sass">
.page-post
  .post-image
    text-align: center
</style>
