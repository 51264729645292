<template lang="html">
  <use :xlink:href="path"></use>
</template>

<script>
  const SPRITE_PATH = require('../assets/svg/sprite.symbol.svg')

  export default {
    name: 'sprite',

    props: {
      name: String
    },

    computed: {
      path() {
        return `${SPRITE_PATH}#${this.name}`
      }
    }
  }
</script>
