<template>
  <form @submit.prevent="submit" class="contact-form">
    <nice-input
      label="Name"
      v-model="resource.name"
      @input="$v.resource.name.$touch"
      :error="nameError" >
    </nice-input>

    <nice-input
      label="Email"
      v-model="resource.email"
      @input="$v.resource.email.$touch"
      type="email"
      :error="emailError" >
    </nice-input>

    <nice-input
      label="Message"
      v-model="resource.message"
      @input="$v.resource.message.$touch"
      type="textarea"
      :error="messageError" >
    </nice-input>

    <button class="btn">Send</button>

    <loader :loading="loading"></loader>
  </form>
</template>

<script>
  import form from '@/mixins/form'

  import {required, email} from 'vuelidate/lib/validators'

  export default {
    name: 'ContactForm',

    mixins: [
      form
    ],

    computed: {
      nameError() {
        if(this.$v.resource.name.$dirty) {
          if(this.$v.resource.name.$invalid) {
            return 'Name is required'
          }
        }

        return ''
      },

      messageError() {
        if(this.$v.resource.message.$dirty) {
          if(this.$v.resource.message.$invalid) {
            return 'Message is required'
          }
        }

        return ''
      },

      emailError() {
        if(this.$v.resource.email.$dirty) {
          if(!this.$v.resource.email.required) {
            return 'Email is required'
          }
          if(!this.$v.resource.email.email) {
            return 'Not a valid email'
          }
        }

        return ''
      }
    },

    validations: {
      resource: {
        name: {required},
        email: {required, email},
        message: {required}
      }
    }
  }
</script>

<style lang="sass">
  .contact-form
    position: relative
</style>
