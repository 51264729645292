<template>
  <div class="page-schools">
    <banner :img="require('../assets/img/banners/kids.jpg')">
      Tennis coaching for all ages &amp; abilities
      <br />
      <router-link class="btn" :to="{name: 'bookings'}">Book now!</router-link>
    </banner>

    <container :title="title">
      <div v-html="content"></div>

      <ul>
        <li v-for="school in schoolsMenu" :key="school.slug">
          <router-link :to="{name: 'school', params: {slug: school.slug}}">
            <span v-html="school.name"></span>, {{ school.town }}
          </router-link>
        </li>
      </ul>
    </container>
  </div>
</template>

<script>
import Banner from '@/components/Banner'
import Container from '@/components/Container'

import page from '@/mixins/page'

import {mapState, mapActions} from 'vuex'

export default {
  name: 'Schools',

  mixins: [page],

  components: {
    Banner,
    Container
  },

  data() {
    return {
      pageSlug: 'schools'
    }
  },

  computed: {
    ...mapState(['schoolsMenu'])
  },

  methods: {
    ...mapActions(['getSchools'])
  },

  serverPrefetch() {
    this.getSchools()
  },

  mounted() {
    if (!this.schoolsMenu.length) {
      this.getSchools()
    }
  }
}
</script>

<style lang="sass">
.page-schools
</style>
