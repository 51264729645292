<template>
  <div class="page-contact">
    <container :title="title">
      <transition name="fade" mode="out-in">
        <div v-if="!sent">
          <div v-html="content"></div>
          <contact-form :loading="loading" v-model="message" @submit="submit" />
        </div>

        <div class="thanks" v-else>
          <h2>Thanks for getting in touch!</h2>
          <p>We will get back to you soon.</p>
        </div>
      </transition>
    </container>
  </div>
</template>

<script>
import Container from '@/components/Container'
import ContactForm from '@/components/forms/ContactForm'

import page from '@/mixins/page'

import {createMessage} from '@/api/messages'

const SUCCESS_STATUS = 'mail_sent'
const WPCF7_UNIT_TAG = 'MB_TENNIS_CONTACT_FORM'

export default {
  name: 'contact',

  mixins: [page],

  components: {
    Container,
    ContactForm
  },

  data() {
    return {
      loading: false,
      pageSlug: 'contact',
      message: {},
      sent: false
    }
  },

  computed: {
    formData() {
      let data = new FormData()

      data.append('your-name', this.message.name)
      data.append('your-email', this.message.email)
      data.append('your-message', this.message.message)
      data.append('_wpcf7_unit_tag', WPCF7_UNIT_TAG)

      return data
    }
  },

  methods: {
    submit() {
      this.loading = true

      createMessage(this.formData).then(({data}) => {
        if (data.status === SUCCESS_STATUS) {
          this.sent = true
        } else {
          alert(
            'Could not deliver message at this time. Please try again later.'
          )
        }

        this.loading = false
      })
    }
  }
}
</script>

<style lang="sass" scoped>
.page-contact
  textarea
    padding: 5px 10px

  .thanks
    text-align: center
</style>
