import Axios from 'axios'

const host = process.env.VUE_APP_API_HOST

export function createMessage(message) {
  const url = `${host}/wp-json/contact-form-7/v1/contact-forms/30/feedback`

  return Axios.request({
    url: url,
    method: 'post',
    data: message,
    config: {headers: {'Content-Type': 'multipart/form-data'}}
  })
}
