import Vue from 'vue'
import Vuex from 'vuex'

import {getPageData} from '@/api/page'
import {getSchoolData, getSchoolsData} from '@/api/schools'
import {getAccreditationsData} from '@/api/accreditations'
import * as posts from '@/api/posts'

Vue.use(Vuex)

export function createStore() {
  return new Vuex.Store({
    getters: {
      latestPost(state){
        return state.posts.sort((a, b) => b.id - a.id)[0]
      }
    },

    state: {
      accreditations: {},
      pages: {},
      schools: {},
      schoolsMenu: [],
      postsBySlug: {},
      posts: [],
      postsReadAt: null
    },

    mutations: {
      setPage(state, {slug, page}) {
        Vue.set(state.pages, slug, page)
      },

      setSchool(state, {slug, school}) {
        Vue.set(state.schools, slug, school)
      },

      setSchoolNames(state, items) {
        let sorted = items.sort((a, b) => {
          return a.name > b.name
        })

        Vue.set(state, 'schoolsMenu', sorted)
      },

      setAccreditations(state, accreditations) {
        accreditations.forEach((accreditation) => {
          let cleaned = {
            featured_image_url:
              accreditation._embedded['wp:featuredmedia']['0'].source_url,
            name: accreditation.title.rendered,
            slug: accreditation.slug,
            weight: parseInt(accreditation.acf.weight) || 0
          }

          Vue.set(state.accreditations, accreditation.slug, cleaned)
        })
      },

      requestPosts() {},
      receivePosts(state, {posts, readAt}) {
        posts.forEach((post) => {
          Vue.set(state.postsBySlug, post.slug, post)
        })

        Vue.set(state, 'postsReadAt', readAt)
        Vue.set(state, 'posts', posts)
      },
      requestedPosts() {},

      requestPost() {},
      receivePost(state, {slug, post}) {
        Vue.set(state.postsBySlug, slug, post)
      },
      requestedPost() {}
    },

    actions: {
      getAccreditations({commit}) {
        return getAccreditationsData()
          .then(({data}) => {
            commit('setAccreditations', data)
          })
          .catch(() => {
            return console.error('Error getting accreditations')
          })
      },

      getPage({commit}, slug) {
        return getPageData(slug).then(({data}) => {
          commit('setPage', {slug, page: data[0]})
        })
      },

      getSchool({commit}, slug) {
        return getSchoolData(slug).then(({data}) => {
          commit('setSchool', {slug, school: data[0]})
        })
      },

      getSchools({commit}) {
        return getSchoolsData().then(({data}) => {
          let items = data.map((school) => {
            return {
              name: school.title.rendered,
              slug: school.slug,
              town: school.acf.town
            }
          })

          commit('setSchoolNames', items)
        })
      },

      getPosts({commit}) {
        commit('requestPosts')

        return posts
          .getPostsData()
          .then(({data}) => {
            const readAt = Date.now()
            commit('receivePosts', {posts: data, readAt})
          })
          .finally(() => {
            commit('requestedPosts')
          })
      },

      getPost({commit}, {slug}) {
        commit('requestPost')

        return posts
          .getPostData(slug)
          .then(({data}) => {
            const post = data[0]

            commit('receivePost', {slug, post})
          })
          .finally(() => {
            commit('requestedPost')
          })
      }
    }
  })
}
