<template>
  <div class="page-home">
    <banner :img="require('../assets/img/banners/ball.jpg')">
      Tennis coaching for all ages &amp; abilities
      <br>
      <router-link class="btn" :to="{name: 'bookings'}">Book now!</router-link>
    </banner>

    <cta>
      <h3>Tennis courses booking now!</h3>
      <p>We are now taking bookings for upcoming courses.</p>
      <router-link class="btn" :to="{name: 'bookings'}">Book now!</router-link>
    </cta>

    <container :title="title">
      <div v-html="content"></div>
    </container>

    <accreditations></accreditations>
  </div>
</template>

<script>
  import Banner from '@/components/Banner'
  import Container from '@/components/Container'
  import Cta from '@/components/CTA'
  import Accreditations from '@/components/Accreditations'

  import page from '@/mixins/page'

  export default {
    name: 'home',

    mixins: [
      page
    ],

    components: {
      Accreditations,
      Container,
      Cta,
      Banner
    },

    data() {
      return {
        pageSlug: 'home'
      }
    }
  }
</script>

<style lang="sass">
  .page-home
    .container
      text-align: center
      padding-bottom: 0
</style>
