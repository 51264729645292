<template>
  <div class="page-posts">
    <container :title="title">
      <div v-html="content"></div>

      <ul class="posts-list">
        <li v-for="post in postsBySlug" :key="post.id">
          <h2>{{ post.title.rendered }}</h2>

          <div>Posted on: {{ post.date | date }}</div>

          <div v-html="post.excerpt.rendered"></div>

          <router-link :to="{name: 'post', params: {slug: post.slug}}">
            Read more...
          </router-link>
        </li>
      </ul>
    </container>
  </div>
</template>

<script>
import Container from '@/components/Container'

import page from '@/mixins/page'

import {mapState, mapActions} from 'vuex'

export default {
  name: 'Posts',

  mixins: [page],

  components: {
    Container
  },

  filters: {
    date(value) {
      if (!value) {
        return ''
      }

      const date = new Date(value)

      const day = date.getDate()
      const month = date.getMonth() + 1
      const year = date.getFullYear()

      return `${day}/${month}/${year}`
    }
  },

  data() {
    return {
      pageSlug: 'posts'
    }
  },

  computed: {
    ...mapState(['postsBySlug', 'postsReadAt'])
  },

  methods: {
    ...mapActions(['getPosts'])
  },

  serverPrefetch() {
    this.getPosts()
  },

  mounted() {
    if (!this.postsReadAt) {
      this.getPosts()
    }
  }
}
</script>

<style lang="sass">
.page-posts
  .posts-list
    list-style: none
    margin: 0
    padding: 0

    .posts-list-item
      padding: 10px 0
</style>
