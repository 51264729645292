import Axios from 'axios'

const host = process.env.VUE_APP_API_HOST
const path = process.env.VUE_APP_API_URL_BASE

export function getPageData(slug) {
  const url = `${host}/${path}/pages/?slug=${slug}`

  return Axios.get(url)
}
