/**
 * Page mixin for view components
 */
import {decodeHtml} from '@/filters'
import {mapState, mapActions} from 'vuex'

const LOADING_TEXT = 'Loading...'

export default {
  metaInfo() {
    return {
      title: decodeHtml(this.title),
      meta: this.metaTags || []
    }
  },

  computed: {
    ...mapState(['pages']),

    content() {
      return this.page.content ? this.page.content.rendered : LOADING_TEXT
    },

    page() {
      return this.pages[this.pageSlug] || {}
    },

    acf() {
      return this.page.acf || {}
    },

    title() {
      return this.page.title ? this.page.title.rendered : LOADING_TEXT
    }
  },

  methods: {
    ...mapActions(['getPage']),
  },

  serverPrefetch() {
    return this.getPage(this.pageSlug)
  },

  mounted() {
    if(!this.pages[this.pageSlug]) {
      this.getPage(this.pageSlug)
    }
  },

  watch: {
    $route() {
      if(!this.pages[this.pageSlug]) {
        this.getPage(this.pageSlug)
      }
    }
  }
}
