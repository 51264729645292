<template>
  <div class="container">
    <h1 v-html="title"></h1>

    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: 'Content',

    props: {
      title: String
    }
  }
</script>

<style lang="sass">
  @import "../stylesheets/base/variables"
  @import "../stylesheets/helpers/mixins"

  .container
    h1
      text-align: center

    h2, h3
      padding-top: $pad

    max-width: $container-width
    margin: auto
    padding: $pad * 3 $pad
    text-align: left

    @include media($media-gt-big)
      padding: $pad * 3 0

    table
      width: 100%
      text-align: left
      border-collapse: collapse

      tr
      td
        border: 1px solid #000
        padding: 10px
        text-align: center

      thead
        font-weight: bold

      > table
        td
          width: 20%
</style>
