<template>
  <footer class="foot">
    <div>
      <router-link :to="{name: 'privacy'}">Privacy policy</router-link>
    </div>

    <div>&copy; MB Tennis {{ currentYear }}</div>

    <div>
      <a
        href="http://nathansplace.co.uk"
        target="_blank"
        title="Website by NathanG"
      >
        <sprite name="nathansplace" class="nathansplace"></sprite>
      </a>
    </div>
  </footer>
</template>

<script>
import Sprite from '@/components/Sprite'

export default {
  name: 'Foot',

  components: {
    Sprite
  },

  computed: {
    currentYear() {
      return new Date().getFullYear()
    }
  }
}
</script>

<style lang="sass">
@import "../stylesheets/base/variables"
@import "../stylesheets/helpers/mixins"

.foot
  padding: $pad
  background: $color-footer
  color: rgba($color-background, 0.4)
  text-align: center
  margin-top: auto
  font-size: 12px

  a
    color: rgba($color-background, 0.4)
    text-decoration: none

    a:hover
      text-decoration: underline

  > div
    padding: $pad / 2

  .nathansplace
    width: 25px
    height: 28px
    opacity: 0.3

  @include media($media-gt-big)
    display: flex
    justify-content: space-between
    align-items: center
</style>
