import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'

import Home from '@/views/Home'
import About from '@/views/About'
import Schools from '@/views/Schools'
import ASTC from '@/views/ASTC'
import Contact from '@/views/Contact'
import School from '@/views/School'
import Bookings from '@/views/Bookings'
import Page from '@/views/Page'
import Posts from '@/views/Posts'
import Post from '@/views/Post'

Vue.use(Router)
Vue.use(Meta)

export function createRouter() {
  return new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
      {
        path: '/',
        name: 'home',
        component: Home,
        meta: {
          slug: 'home'
        }
      },

      {
        path: '/about',
        name: 'about',
        component: About,
        meta: {
          slug: 'about'
        }
      },

      {
        path: '/schools',
        name: 'schools',
        component: Schools
      },

      {
        path: '/schools/:slug',
        name: 'school',
        component: School,
        props: true
      },

      {
        path: '/ashstead-squash-and-tennis-club',
        name: 'astc',
        component: ASTC
      },

      {
        path: '/contact',
        name: 'contact',
        component: Contact
      },

      {
        path: '/privacy',
        name: 'privacy',
        component: Page,
        meta: {
          pageSlug: 'privacy'
        }
      },

      {
        path: '/bookings',
        name: 'bookings',
        component: Bookings,
        meta: {
          pageSlug: 'bookings'
        }
      },

      {
        path: '/tennis-camps',
        name: 'posts',
        component: Posts
      },

      {
        path: '/tennis-camps/:slug',
        name: 'post',
        props: true,
        component: Post
      }
    ]
  })
}
