import Axios from 'axios'

const host = process.env.VUE_APP_API_HOST
const path = process.env.VUE_APP_API_URL_BASE

export function getAccreditationsData() {
  const url = `${host}/${path}/accreditations?per_page=50&_embed`

  return Axios.get(url)
}
