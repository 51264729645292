<template>
  <div class="error" v-if="error">
    {{error}}
  </div>
</template>

<script>
  export default {
    name: 'Error',

    props: {
      error: String
    }
  }
</script>

<style lang="sass">
  .error
    color: red
</style>
