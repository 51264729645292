<template>
  <div class="input">
    <label :for="_uid">{{label}}:</label>

    <input
      v-if="!textarea"
      :id="_uid"
      v-model="resource">

    <textarea
      v-else
      v-model="resource"
      :id="_uid">
    </textarea>

    <error :error="error"></error>
  </div>
</template>

<script>
  import Error from '@/components/Error'
  import input from '@/mixins/input'

  export default {
    name: 'Input',

    mixins: [
      input
    ],

    props: {
      label: String,
      error: String,

      type: {
        type: String,
        default: 'text'
      }
    },

    components: {
      Error
    },

    computed: {
      textarea() {
        return this.type === 'textarea'
      }
    },
  }
</script>

<style lang="sass">
  .input
    label
      display: inline-block

    input, textarea, button
      width: 100%
      margin-bottom: 30px

    textarea
      min-height: 100px
</style>
