<template>
  <div class="accreditations">
    <lazy-img
      class="accreditation"
      v-for="accreditation in mapped"
      :key="accreditation.slug"
      :description="accreditation.name | decodeHtml"
      :url="accreditation.featured_image_url">
    </lazy-img>
  </div>
</template>

<script>
  import LazyImg from '@/components/LazyImg'

  import {mapState, mapActions} from 'vuex'

  import {decodeHtml} from '@/filters'

  export default {
    name: 'Accreditations',

    components: {
      LazyImg
    },

    filters: {
      decodeHtml
    },

    computed: {
      ...mapState(['accreditations']),

      mapped() {
        return Object.keys(this.accreditations)
          .map((key) => {
            return this.accreditations[key]
          })
          .sort((a, b) => {
            return a.weight > b.weight
          })
      }
    },

    methods: {
      ...mapActions(['getAccreditations']),
    },

    serverPrefetch() {
      return this.getAccreditations()
    },

    mounted() {
      if(!this.mapped.length) {
        this.getAccreditations()
      }
    }
  }
</script>

<style lang="sass">
  @import "../stylesheets/base/variables"
  @import "../stylesheets/helpers/mixins"

  .accreditations
    width: 100%
    padding-top: 60px
    max-width: 900px
    padding: $pad
    margin: auto

    @include media($media-gt-big)
      display: flex
      flex-wrap: wrap
      align-items: center
      justify-content: space-between

    .accreditation
      display: block
      margin: auto
      max-width: 100px

      @include media($media-gt-big)
        max-width: 100px
        display: inline-block
        flex: 1
        padding: 0 10px

        &:first-of-type
          padding-left: 0

        &:last-of-type
          padding-right: 0

      img
        width: 100%
        height: auto
</style>
