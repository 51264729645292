import Axios from 'axios'

const host = process.env.VUE_APP_API_HOST
const path = process.env.VUE_APP_API_URL_BASE

export function getSchoolsData() {
  const url = `${host}/${path}/school`

  return Axios.get(url)
}

export function getSchoolData(slug) {
  const url = `${host}/${path}/school/?slug=${slug}`

  return Axios.get(url)
}
