<template>
  <div class="page-bookings">
    <container :title="title">
      <transition name="fade" mode="out-in">
        <div v-if="!sent">
          <div v-html="content"></div>

          <booking-form
            :loading="loading"
            v-model="booking"
            @submit="enquire"
          />
        </div>

        <div class="thanks" v-else>
          <h2>Thanks for enquiring!</h2>
          <p>We will get back to you soon.</p>
        </div>
      </transition>

      <p>
        Please forward payment by Bacs to: Ms A L Beaumont, 09-01-29, 21628261
      </p>

      <transition name="fade">
        <p v-if="!sent">
          For further information please contact Mandy by email:
          info@mbtennis.co.uk or by phone: 07974 186624
        </p>
      </transition>

      <p>
        Thank you!
      </p>
    </container>
  </div>
</template>

<script>
import BookingForm from '@/components/forms/BookingForm'
import Container from '@/components/Container'

import {createEnquiry} from '@/api/enquiries'

import page from '@/mixins/page'

const SUCCESS_STATUS = 'mail_sent'
const WPCF7_UNIT_TAG = 'MB_TENNIS_BOOKING_FORM'

export default {
  name: 'Bookings',

  mixins: [page],

  components: {
    BookingForm,
    Container
  },

  data() {
    return {
      pageSlug: 'bookings',
      booking: {},
      loading: false,
      sent: false
    }
  },

  computed: {
    formData() {
      let data = new FormData()

      data.append('your-name', this.booking.name)
      data.append('contactName', this.booking.contactName)
      data.append('email', this.booking.email)
      data.append('phone', this.booking.phone)
      data.append('dob', this.booking.dob)
      data.append('medical', this.booking.medical)
      data.append('course', this.booking.course)
      data.append('course-day', this.booking.day)
      data.append('time', this.booking.time)
      data.append('cost', this.booking.cost)
      data.append('_wpcf7_unit_tag', WPCF7_UNIT_TAG)

      return data
    }
  },

  methods: {
    enquire() {
      this.loading = true

      createEnquiry(this.formData).then(({data}) => {
        if (data.status === SUCCESS_STATUS) {
          this.sent = true
        } else {
          alert(
            'Could not deliver message at this time. Please try again later.'
          )
        }

        this.loading = false
      })
    }
  }
}
</script>

<style lang="sass" scoped>
.page-bookings

  .thanks
    text-align: center
</style>
