<template>
  <div class="cta">
    <div class="cta-inner">
      <slot></slot>
    </div>
  </div>
</template>

<script>
  export default {
    name: "CTA"
  }
</script>

<style lang="sass">
  @import "../stylesheets/base/variables"

  .cta
    background: #738c76
    position: relative

    .btn
      padding: 5px 10px
      font-size: 16px
      margin: 0

    .cta-inner
      padding: $pad
      margin: auto
      color: $color-background
      font-size: 22px
      text-align: center

      h3
        padding: 0
        margin: 0 0 15px

      p
        padding: 0
        font-size: 16px
        color: $color-background
        margin: 15px
</style>
