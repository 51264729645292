import Vue from 'vue'
import App from './App.vue'

import Vuelidate from 'vuelidate'
import VueAnalytics from 'vue-analytics'

import {createRouter} from './router'
import {createStore} from './store'

Vue.use(Vuelidate)

Vue.config.productionTip = false

export function createApp() {
  const router = createRouter()
  const store = createStore()
  const production = process.env.NODE_ENV === 'production'

  Vue.use(VueAnalytics, {
    id: process.env.VUE_APP_ANALYTICS_ID,
    router,
    debug: {
      enabled: !production,
      sendHitTask: production
    }
  })

  const app = new Vue({
    router,
    store,
    render: h => h(App)
  })

  return {app, router, store}
}
