<template>
  <div class="page-page">
    <container :title="title">
      <div v-html="content"></div>
    </container>
  </div>
</template>

<script>
  import Container from '@/components/Container'

  import page from '@/mixins/page'

  export default {
    name: 'Page',

    mixins: [page],

    components: {
      Container
    },

    computed: {
      pageSlug() {
        return this.$route.meta.pageSlug
      }
    }
  }
</script>

<style lang="sass">
  .page-page
    .container
      p, h2, h3
        text-align: left
</style>
