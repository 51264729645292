<template>
  <div class="page-school">
    <banner :img="require('../assets/img/banners/kids.jpg')">
      Tennis Coaching at <span v-html="name"></span>, {{town}}
      <br>
      <a v-if="url" target="_blank" class="btn" :href="url">Book now!</a>
    </banner>

    <container :title="title">
      <div v-html="content"></div>

      <div class="availability">
        <p v-if="open">This tennis coaching programme is available to members of the public as well as students of <span v-html="name"></span>, {{town}}.</p>
        <p v-else>This tennis coaching programme is available to students of <span v-html="name"></span>, {{town}} only.</p>
      </div>
    </container>
  </div>
</template>

<script>
  import Banner from '@/components/Banner'
  import Container from '@/components/Container'

  import {mapState, mapActions} from 'vuex'

  const LOADING_TEXT = 'Loading...'

  export default {
    name: 'School',

    components: {
      Banner,
      Container
    },

    props: {
      slug: {
        type: String,
        required: true
      }
    },

    computed: {
      ...mapState(['schools']),

      acf() {
        return this.school.acf || {}
      },

      url() {
        return this.acf.url
      },

      open() {
        return this.acf.availability === 'Public'
      },

      school() {
        if(!this.schools && !this.schools[this.slug]) return {}
        return this.schools[this.slug] || {}
      },

      name() {
        return this.school.title ? this.school.title.rendered : LOADING_TEXT
      },

      title() {
        return `${this.name}, ${this.town} Tennis Coaching`
      },

      town() {
        return this.acf.town || LOADING_TEXT
      },

      content() {
        if(!this.school.content) return LOADING_TEXT
        return this.school.content.rendered
      }
    },

    methods: {
      ...mapActions(['getSchool']),
    },

    metaInfo() {
      return {
        title: `${this.title}`
      }
    },

    serverPrefetch() {
      this.getSchool(this.slug)
    },

    mounted() {
      if(!this.schools[this.slug]) {
        this.getSchool(this.slug)
      }
    }
  }
</script>

<style lang="sass">
  .page-school
    .availability
      font-weight: bold
</style>
