<template>
  <div class="page-about">
    <banner :img="require('../assets/img/banners/ball.jpg')">
      Tennis coaching for all ages &amp; abilities
      <br>
      <router-link class="btn" :to="{name: 'bookings'}">Book now!</router-link>
    </banner>

    <container :title="title">
      <div v-html="content"></div>
    </container>

    <accreditations></accreditations>
  </div>
</template>

<script>
  import Banner from '@/components/Banner'
  import Container from '@/components/Container'
  import Accreditations from '@/components/Accreditations'

  import page from '@/mixins/page'

  export default {
    name: 'About',

    mixins: [
      page
    ],

    components: {
      Accreditations,
      Banner,
      Container
    },

    data() {
      return {
        pageSlug: 'about'
      }
    }
  }
</script>

<style lang="sass">
  @import "../stylesheets/base/variables"

  .page-about
    .container
      padding-bottom: $pad
</style>
