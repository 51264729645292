<template>
  <div id="app" :class="{app: 1, 'is-navigating': navigating, 'is-scrolling': scroll}">
    <header class="header">
      <router-link :to="{name: 'home'}" title="MB Tennis">
        <img class="logo" src="./assets/img/logo.png" alt="MB Tennis">
      </router-link>

      <button class="nav-button" @click="toggleNav">
        <sprite name="menu"></sprite>
      </button>

      <navigation
        @close="toggleNav"
        :active="navigating">
      </navigation>
    </header>

    <main>
      <transition name="fade" mode="out-in">
        <router-view></router-view>
      </transition>
    </main>

    <foot></foot>
  </div>
</template>

<script>
  import throttle from 'lodash.throttle'

  import Sprite from '@/components/Sprite'
  import Navigation from '@/components/Navigation'
  import Foot from '@/components/Foot'

  const NAVIGATING_CLASS = 'is-navigating'

  export default {
    name: 'app',

    /**
     * Vue meta defaults
     */
    metaInfo: {
      title: 'MB Tennis',

      meta: [
        // {vmid: 'description', name: 'description', content: 'Some description'},
        //
        // {vmid: 'twitter:card', name: "twitter:card", content: "summary"},
        // {vmid: 'twitter:site', name: "twitter:site", content: "@publisher_handle"},
        // {vmid: 'twitter:creator', name: "twitter:creator", content: "@author_handle"},
        //
        // {vmid: 'og:title', property: "og:title", content: "Title Here"},
        // {vmid: 'og:type', property: "og:type", content: "article"},
        // {vmid: 'og:url', property: "og:url", content: "http://www.example.com/"},
        // {vmid: 'og:image', property: "og:image", content: "http://example.com/image.jpg"},
        // {vmid: 'og:description', property: "og:description", content: "Description Here" },
        // {vmid: 'og:site_name', property: "og:site_name", content: "Site Name, i.e. Moz" }
      ]
    },

    components: {
      Foot,
      Navigation,
      Sprite
    },

    data() {
      return {
        navigating: false,
        scroller: null,
        scroll: false
      }
    },

    methods: {
      toggleNav() {
        this.navigating = !this.navigating
      }
    },

    watch: {
      navigating() {
        if(this.navigating) {
          document.body.classList.add(NAVIGATING_CLASS)
        } else {
          document.body.classList.remove(NAVIGATING_CLASS)
        }
      }
    },

    mounted() {
      // Keep track of weather user has scrolled down or not
      this.scroller = window.addEventListener('scroll', throttle(() => {
        this.scroll = !!window.scrollY
      }), 100, {trailing: true})
    },

    destroyed() {
      window.removeEventListener('scroll', this.scroller)
    }
  }
</script>

<style lang="sass">
  @import "./stylesheets/base/variables"
  @import "./stylesheets/base/typography"

  @import "./stylesheets/helpers/mixins"

  @import "./stylesheets/helpers/transitions"

  $transition-delay: 0.2s

  *, *:after
    box-sizing: border-box

  button
    cursor: pointer

  body
    margin: 0
    padding: 0
    background: #a8bda8
    overflow-x: hidden

    &.is-navigating
      overflow-y: hidden

      @include media($media-gt-big)
        overflow-y: auto

  .app
    background: $color-background
    width: 100%
    max-width: $max-width
    margin: auto
    min-height: 100vh
    display: flex
    flex-direction: column

  .header
    padding: $pad
    background: $color-background
    transition: padding $transition-duration $transition-easing

    .is-scrolling &
      transition-delay: $transition-delay

    @include media($media-gt-big)
      .is-scrolling &
        padding: $pad / 4 $pad / 2

    .nav-button
      position: absolute
      top: $pad / 2
      right: $pad / 2
      z-index: 1000
      border: 0
      background-size: cover
      width: 30px
      height: 30px
      padding: 0
      background: none

      .sprite
        width: 100%
        height: 100%
        fill: $color-main

      @include media($media-gt-big)
        display: none

    @include media($media-gt-big)
      display: flex
      flex-direction: columns
      justify-content: space-between
      position: sticky
      top: 0
      z-index: 900

  .logo
    margin: auto
    width: 100%
    max-width: 200px
    display: block
    transition: max-width $transition-duration $transition-easing

    .is-scrolling &
      transition-delay: $transition-delay

    @include media($media-gt-big)
      margin: 0

    @include media($media-gt-big)
      .is-scrolling &
        max-width: 100px

  .btn
    display: inline-block
    padding: 7px 10px
    background: $color-main
    color: #ffffff
    margin: 10px
    border: 0

    &:hover
      text-decoration: none
</style>
