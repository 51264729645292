<template lang="html">
  <div class="lazy-img" :class="{'is-loading': !active}">
    <transition name="fade" mode="out-in">
      <p v-if="!active">Loading...</p>
      <img :src="url" :alt="description" v-else draggable="false">
    </transition>
  </div>
</template>

<script>
  export default {
    name: 'ResponsiveImg',
    introduction: 'Lazy load image at right size',
    description: '',
    token: '<lazy-img url="http://molly.nathansplace.co.uk/100x100.jpg" description="molly"></lazy-img>',

    props: {
      url: {
        type: String,
        default: '',
        note: 'The source of the image to be loaded in'
      },

      description: {
        type: String,
        default: '',
        note: 'Used in image alt tag'
      }
    },

    data() {
      return {
        loading: true
      }
    },

    computed: {
      active() {
        return this.url && !this.loading
      }
    },

    methods: {
      getData() {
        if(this.url) {
          let img = new Image()

          this.loading = true

          img.addEventListener('load', () => {
            this.loading = false
          })

          img.src = this.url
        }
      }
    },

    mounted() {
      this.getData()
    },

    watch: {
      imagePath() {
        this.getData()
      }
    }
  }
</script>

<style lang="sass">
  .responsive-img
    .is-loading

    > img
      width: 100%
</style>
