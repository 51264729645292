<template>
  <form @submit.prevent="submit" class="booking-form">
    <nice-input
      label="Participant name"
      v-model="resource.name"
      @input="$v.resource.name.$touch"
      :error="nameError"
    />

    <nice-input
      label="Parent / carer name (under 18's only)"
      v-model="resource.contactName"
      @input="$v.resource.contactName.$touch"
      :error="contactNameError"
    />

    <nice-input
      label="Email"
      v-model="resource.email"
      @input="$v.resource.email.$touch"
      type="email"
      :error="emailError"
    />

    <nice-input
      label="Mob"
      v-model="resource.phone"
      @input="$v.resource.phone.$touch"
      :error="phoneError"
    />

    <nice-input
      label="Date of birth (under 18's only)"
      v-model="resource.dob"
      @input="$v.resource.dob.$touch"
      type="date"
      :error="dobError"
    />

    <nice-input
      label="Medical condiditions / allergies"
      v-model="resource.medical"
    />

    <nice-input
      label="Course title"
      v-model="resource.course"
      :error="courseError"
      @input="$v.resource.course.$touch"
    />

    <nice-input
      label="Day(s)"
      type="date"
      v-model="resource.day"
      @input="$v.resource.day.$touch"
      :error="dayError"
    />

    <nice-input
      label="Time"
      v-model="resource.time"
      @input="$v.resource.time.$touch"
      :error="timeError"
    />

    <nice-input
      label="Cost"
      v-model="resource.cost"
      @input="$v.resource.cost.$touch"
      :error="costError"
    />

    <button class="btn">Enquire</button>

    <loader :loading="loading"></loader>
  </form>
</template>

<script>
import form from '@/mixins/form'

import {required, email} from 'vuelidate/lib/validators'

export default {
  name: 'BookingForm',

  mixins: [form],

  computed: {
    nameError() {
      if (this.$v.resource.name.$dirty) {
        if (this.$v.resource.name.$invalid) {
          return 'Name is required'
        }
      }

      return ''
    },

    contactNameError() {
      if (this.$v.resource.contactName.$dirty) {
        if (this.$v.resource.contactName.$invalid) {
          return 'contactName is required'
        }
      }

      return ''
    },

    emailError() {
      if (this.$v.resource.email.$dirty) {
        if (!this.$v.resource.email.required) {
          return 'Email is required'
        }
        if (!this.$v.resource.email.email) {
          return 'Not a valid email'
        }
      }

      return ''
    },

    phoneError() {
      if (this.$v.resource.phone.$dirty) {
        if (this.$v.resource.phone.$invalid) {
          return 'Phone is required'
        }
      }

      return ''
    },

    dobError() {
      if (this.$v.resource.dob.$dirty) {
        if (this.$v.resource.dob.$invalid) {
          return 'Date of birth is required'
        }
      }

      return ''
    },

    courseError() {
      if (this.$v.resource.course.$dirty) {
        if (this.$v.resource.course.$invalid) {
          return 'Course is required'
        }
      }

      return ''
    },

    dayError() {
      if (this.$v.resource.day.$dirty) {
        if (this.$v.resource.day.$invalid) {
          return 'Day is required'
        }
      }

      return ''
    },

    timeError() {
      if (this.$v.resource.time.$dirty) {
        if (this.$v.resource.time.$invalid) {
          return 'Time is required'
        }
      }

      return ''
    },

    costError() {
      if (this.$v.resource.cost.$dirty) {
        if (this.$v.resource.cost.$invalid) {
          return 'Cost is required'
        }
      }

      return ''
    }
  },

  validations: {
    resource: {
      name: {required},
      contactName: {},
      email: {required, email},
      phone: {required},
      dob: {},
      course: {required},
      day: {required},
      time: {required},
      cost: {required}
    }
  }
}
</script>

<style lang="sass">
.booking-form
  position: relative

  select
    width: 100%
    margin-bottom: 30px
</style>
