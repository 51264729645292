<template lang="html">
  <nav :class="{navigation: 1, 'is-active': active}">
    <ul>
      <li v-for="item in menuItems" :key="item.name">
        <router-link
          :class="item.name.toLowerCase()"
          :to="item.target"
          @click.native="close"
          :title="item.title"
        >
          {{ item.name }}
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
import {mapActions, mapState, mapGetters} from 'vuex'

export default {
  name: 'Navigation',

  props: {
    active: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      items: [
        {
          name: 'Home',
          title: 'Home',
          target: {name: 'home'},
          meta: {},
          order: 1
        },

        {
          name: 'About',
          title: 'About MB Tennis',
          target: {name: 'about'},
          order: 2
        },

        {
          name: 'ASTC',
          title: 'Ashstead Squash and Tennis Club',
          target: {name: 'astc'},
          order: 3
        },

        {
          name: 'Schools',
          title: 'Schools tennis coaching',
          target: {name: 'schools'},
          order: 5
        },

        {
          name: 'Contact',
          title: 'Contact MB Tennis',
          target: {name: 'contact'},
          order: 6
        }
      ]
    }
  },

  computed: {
    ...mapGetters(['latestPost']),
    ...mapState(['postsReadAt']),

    menuItems() {
      const out = [...this.items]

      if (!!this.latestPost) {
        out.push({
          name: 'Tennis camps',
          title: 'Tennis camps',
          target: {name: 'post', params: {slug: this.latestPost.slug}},
          order: 4
        })
      }

      return out.sort((a, b) => {
        return b.order - a.order
      })
    }
  },

  methods: {
    ...mapActions(['getPosts']),

    close() {
      this.$emit('close')
    }
  },

  mounted() {
    this.getPosts()
  },

  serverPrefetch() {
    return this.getPosts()
  }
}
</script>

<style lang="sass">
@import "../stylesheets/base/variables"
@import "../stylesheets/helpers/mixins"

.navigation
  display: flex
  position: absolute
  top: 0
  right: 0
  bottom: 0
  left: 0
  z-index: 900
  background: $color-background
  transform: translateX(100%)
  transition: opacity, transform
  transition-duration: $transition-duration
  transition-easing: $transition-easing
  opacity: 0
  align-items: center

  &.is-active
    transform: translateX(0%)
    opacity: 1

  @include media($media-gt-big)
    position: relative
    transform: translateX(0%)
    opacity: 1

    .home
      display: none

  .close
    @include media($media-gt-big)
      display: none

  ul
    list-style: none
    display: inline-block
    width: 100%
    padding: 0

    @include media($media-gt-big)
      margin: auto 0 0 0
      width: auto

  li
    font-family: $font-main
    font-weight: bold
    color: $color-headings
    text-align: center
    padding: $pad / 2

    @include media($media-gt-big)
      display: inline-block
      padding: 0 $pad

      &:last-of-type
        padding-right: 0

    a
      text-decoration: none
      color: $color-headings
      transition: color border-color
      transition-duration: $transition-duration
      transtion-easing: $transition-easing
      border-bottom: 2px solid
      border-color: transparent

      &:hover
        color: $color-highlight
        border-color: $color-highlight

      &.router-link-active
        color: $color-highlight
</style>
